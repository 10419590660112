<template>
  <b-modal :id="'update-booking'+room.keyRoom" ok-only no-close-on-backdrop size="md" centered hide-footer :title="'Autorizar actualización: '+room.RoomTypeName">
    <ValidationObserver ref="observerRateModal" v-slot="{ invalid }" >
      <b-form @submit.prevent="sendDataUserAuthorizeRate">
        <b-row>
          <b-col md="12" class="mb-2">
             <span>Usuario</span>
              <ValidationProvider rules="required" name="Usuario">
                <b-input-group label="Usuario" slot-scope="{ valid, errors }">
                  <b-form-input  type="text"
                    v-model="userAuthUpdateBooking.username"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :disabled="isAuthorizingRate || checkingIfUserIsValid"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="warning"
                      @click="isValidUserUpdateBooking"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.top="'Validar usuario'"
                      :disabled="isAuthorizingRate || checkingIfUserIsValid"
                    >
                      <div v-if="checkingIfUserIsValid">
                        <b-spinner small type="grow"></b-spinner>...
                      </div>
                      <feather-icon icon="UnlockIcon" size="14" v-if="!checkingIfUserIsValid"/>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </ValidationProvider>
            </b-col>
            <b-col md="12">
              <ValidationProvider rules="required" name="Clave de autorización">
                <b-form-group label="Clave de autorización" slot-scope="{ valid, errors }">
                  <b-form-input
                    type="password"
                    v-model="userAuthUpdateBooking.code"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :disabled="isAuthorizingRate"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{ errors[0] ? 'Se debe indicar la clave de autorización del usuario' : '' }} </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
             <b-col md="12">
              <ValidationProvider rules="required" name="Comprobante para autorización">
                <b-form-group label="Comprobante para autorización" slot-scope="{ valid, errors }">
                  <b-form-file
                    v-model="file"
                    @input="setImgFile(file)"
                    placeholder="Adjunte una o más imagenes como evidencia..."
                    drop-placeholder="Drop file here..."
                    :state="errors[0] ? false : (valid ? true : null)"
                    browse-text="Buscar imagen"
                    :disabled="isAuthorizingRate"
                    multiple
                  />
                  <b-form-invalid-feedback>{{ errors[0] ? 'Se debe adjuntar la evidencia para la autorización' : '' }} </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <b-card-text class="my-1">
                {{fileMessage}}<strong v-for="f in file" :key="f.name">{{ f ? f.name : '' }},&nbsp;</strong>
              </b-card-text>
            </b-col>
            <b-col md="12">
              <div class="float-right">
                <b-button 
                  variant="primary" 
                  type="submit"
                  :disabled="invalid || isAuthorizingRate"
                >
                  <div v-if="isAuthorizingRate">
                    <b-spinner small type="grow"></b-spinner>Autorizando...
                  </div>
                  <div v-if="!isAuthorizingRate">
                    <feather-icon
                      icon="SaveIcon"
                      size="14"
                    /> Autorizar y actualizar
                  </div>
                </b-button>
              </div>
            </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
	</b-modal>
</template>

<script>
import { mapState,mapActions,  mapMutations } from 'vuex'
import { toJson } from '@/helpers/helpers'
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { showAlertMessage, filterFilesByType, filterFilesBySize, filterFilesByName } from '@/helpers/helpers'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props:{
    room:{
      type: Object,
      required: true
    }
  },
  data(){
    return {
      file: null,
      checkingIfUserIsValid: false,
      isAuthorizingRate: false
    }
  },
  computed:{
    ...mapState('auth',['user']),
    ...mapState('mySales',['userAuthUpdateBooking', 'bookingSelected']),
    fileMessage(){
      return !this.file?.length ? '' : this.file?.length > 1 ? 'Imagenes seleccionadas' : 'Imagen seleccionada'
    }
  },
  methods:{
    ...mapActions('mySales',['updateBooking','validateUser','authorizeRateUser']),
    ...mapMutations('mySales',['setDataUserAuthorization','unsetDataUserAuthorization','setUserAuthorizationUrl']),
    async isValidUserUpdateBooking(){
      this.checkingIfUserIsValid = true
      const userUpdateBooking  = toJson({...this.userAuthUpdateBooking} )
      const { username, code } = userUpdateBooking
      const payloadCheck = { username, code, canalVenta: this.user.sectionCanal.id }
      this.setDataUserAuthorization(userUpdateBooking)
      let { idUser, isInvalid } = await this.validateUser(payloadCheck)
      const userValidated = { code, idUser, isInvalid, username }
      this.setDataUserAuthorization(userValidated) //muto state de user que autoriza
      this.checkingIfUserIsValid = false
      if(!isInvalid) showAlertMessage('Usuario encontrado', 'InfoIcon', '🎉🎉 El usuario sí es válido', 'success', 4000, 'bottom-right')
    },
    async sendDataUserAuthorizeRate(){
      this.isAuthorizingRate = true
      const userUpdateBooking  = toJson({ ...this.userAuthUpdateBooking })
      const { username, code } = userUpdateBooking
      //valido si user y muto state
      let { idUser, isInvalid } = await this.validateUser({ username,  canalVenta: this.user.sectionCanal.id }) //valido existencia de user
      const userValidated = { code, idUser, isInvalid, username }
      this.setDataUserAuthorization(userValidated) //muto state de user que autoriza
      //realizo proceso de autorizacion que genera una url img
      const user = toJson({ ...this.userAuthUpdateBooking })
      let { isInvalid:esInvalido } = user
      if( esInvalido ){ //si user es invalido
        showAlertMessage('No existe usuario, datos incorrectos', 'InfoIcon', '😪😪 Usuario incorrecto, o el usuario no existe', 'danger', 4000, 'bottom-right')
        this.isAuthorizingRate = true
      }  
      if( !esInvalido ){ //si user es valido, subo y obtengo una url de img, se agrega como url de autorizacion para user
        const validfile = this.filteringFiles()
        if (validfile){
          const payloadAutorize = { user: idUser, code, username, file: this.file, isPriceMatch: true }
          const url = await this.authorizeRateUser(payloadAutorize)
          if(!url){
            this.isAuthorizingRate = false
            return false
          }
          this.setUserAuthorizationUrl(url)
          this.$emit('set-payload-update-booking')
        }
      }
      this.isAuthorizingRate = false
    },
    setImgFile(file){
      if(file) this.file = file
      else {
        const text = `😪😪 Tiene que adjuntar un archivo de tipo imagen`
				return showAlertMessage( 'No hay imagen', 'InfoIcon', text, 'danger', 4000, 'bottom-right')
      }
    },
    filteringFiles(){
      if (!this.file || this.file.length == 0) {
        showAlertMessage( 'Sin archivos', 'InfoIcon', 'Por favor, elija un archivo', 'warning', 4000, 'bottom-right')
        return false
      }

      // if (this.file.length > 5) {
      if (this.bookingSelected.urlauths.length > 5 || (this.file.length + this.bookingSelected.urlauths.length) > 5){
        this.file = null
        showAlertMessage( 'Maximo alcanzado', 'InfoIcon', 'No puedes subir más de 5 archivos', 'warning', 4000, 'bottom-right')
        return false
      }

      const filteredByType = filterFilesByType(this.file)
      if (!filteredByType) return false

      const filteredBySize = filterFilesBySize(filteredByType, 2202009.6)
      if (!filteredBySize) return false

      const filteredByName = filterFilesByName(filteredBySize)
      if (!filteredByName) return false

      return true
    }
  }
}
</script>
